/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type GeoJSONGeometryCollectionAllOfType = typeof GeoJSONGeometryCollectionAllOfType[keyof typeof GeoJSONGeometryCollectionAllOfType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GeoJSONGeometryCollectionAllOfType = {
  GeometryCollection: 'GeometryCollection',
} as const;
