/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type GeoJSONMultiPolygonAllOfType = typeof GeoJSONMultiPolygonAllOfType[keyof typeof GeoJSONMultiPolygonAllOfType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GeoJSONMultiPolygonAllOfType = {
  MultiPolygon: 'MultiPolygon',
} as const;
